<!--
 * @Description: 
 * @Author: xiaoq
 * @Date: 2024-06-13 10:57:01
 * @LastEditTime: 2024-06-13 11:19:53
 * @LastEditors: xiaoq
-->
<template>
  <footer class="footer min-w-1400">
    <div class="footer-content">
      <!-- <p>Supervisado por la Comisión Nacional Bancaria y la Comisión Reguladora de Valores</p> -->
      <p>Copyright © Lumo Financiera del Centro, S.A. de C.V., SOFOM, E.N.R.</p>
    </div>
  </footer>
</template>

<script>
export default {};
</script>

<style scoped>
.footer {
   margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background-color: #000000FF ;
  color: white;
  min-width: 1400px;
  max-width: 1620px;
  height: 100px;
}

.footer-content {
  font-size: 17px;
  text-align: center;
  margin: 0 auto;
}
</style>
